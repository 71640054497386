import { Box, Tab, Tabs, useTheme } from "@mui/material";
import React, { useState } from "react";
import Journeys from "./Journeys";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function JourneysTab() {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box sx={{ background: theme.palette.background.paper }}>
      <Tabs
        value={currentTab}
        onChange={(_event: React.SyntheticEvent, value: number) =>
          setCurrentTab(value)
        }
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label={t("journeys.titleOwn")} value={0}></Tab>
        <Tab label={t("journeys.titleShared")} value={1}></Tab>
      </Tabs>
      <TabPanel value={currentTab} index={0}>
        <Journeys type="own" />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Journeys type="shared" />
      </TabPanel>
    </Box>
  );
}
