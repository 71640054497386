import React from "react";
import {
  StatisticsContextDefault,
  StatisticsContextType,
} from "../interfaces/StatisticsContext";
import useAuth from "../../auth/hooks/useAuth";
import { AxiosResponse } from "axios";
import { IDetailedStatistics, ISimpleStatistics } from "@jozys/db-delay-types";

export const StatisticsContext = React.createContext<StatisticsContextType>(
  StatisticsContextDefault
);

export default function StatisticsProvider(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const auth = useAuth();
  const [simpleStatistics, setSimpleStatistics] =
    React.useState<ISimpleStatistics>({
      amountOfJourneys: 0,
      punctuality: 0,
      highestDelay: 0,
      averageDelay: { minutes: 0, seconds: 0 },
    });

  const [detailedStatistics, setDetailedStatistics] =
    React.useState<IDetailedStatistics>({
      amountOfJourneys: 0,
      punctuality: 0,
      highestDelay: 0,
      averageDelay: { minutes: 0, seconds: 0 },
      sumOfDelay: "",
      journeysAlone: 0,
      journeysTogether: 0,
      punctualityAlone: 0,
      punctualityTogether: 0,
    });
  const getSimpleStatistics = async () => {
    const response: AxiosResponse<ISimpleStatistics> = await global.axios.get(
      "/statistics/",
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      }
    );
    setSimpleStatistics(response.data);

    return response.data;
  };

  const getDetailedStatistics = async () => {
    const response: AxiosResponse = await global.axios.get(
      "/statistics/?detailed=true",
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      }
    );
    setDetailedStatistics(response.data);
    return response.data;
  };

  return (
    <StatisticsContext.Provider
      value={{
        getSimpleStatistics,
        simpleStatistics,
        detailedStatistics,
        getDetailedStatistics,
      }}
    >
      {props.children}
    </StatisticsContext.Provider>
  );
}
