import { SvgIconComponent } from "@mui/icons-material";
import { Container, Typography, useTheme } from "@mui/material";
import moment from "moment";
import React from "react";
import { parseSize, Size } from "../utils/size";

export interface DestinationProps {
  title: string;
  time: Date;
  icon: SvgIconComponent;
  size?: Size;
}

export default function Destination(props: DestinationProps) {
  const theme = useTheme();
  return (
    <Container sx={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <props.icon
          color={"secondary"}
          sx={{
            fontSize: parseSize(props.size ?? "medium"),
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(1),
          }}
        >
          <Typography
            fontWeight={"bold"}
            fontSize={parseSize(props.size ?? "medium", 0.75)}
          >
            {props.title}
          </Typography>
          <Typography textAlign={"center"}>
            {moment(props.time).format("HH:mm").toString()}
          </Typography>
        </div>
      </div>
    </Container>
  );
}
