import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguageSettings from "./LanguageSettings";
import UserAccountSettings from "./UserAccountSettings";

export default function Settings() {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <Typography variant="h3" sx={{ flex: 1 }}>
        {t("settings.title")}
      </Typography>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          width: window.innerWidth < 500 ? "100%" : "70%",
        }}
      >
        <UserAccountSettings />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          marginTop: theme.spacing(2),
          width: window.innerWidth < 500 ? "100%" : "70%",
        }}
      >
        <LanguageSettings />
      </div>
    </div>
  );
}
