import { ITrainJourneyDetailed } from "@jozys/db-delay-types";
import { StationMarker } from "../design-system/JourneyMap";

export const getStations = (
  trains: ITrainJourneyDetailed[]
): StationMarker[] => {
  let stations: StationMarker[] = trains.map((train, i) => {
    return {
      label: train.startStation.name,
      lat: train.startStation.lat ?? 51.163361, // Center of Germany as Fallback
      lon: train.startStation.lon ?? 10.447683, // Center of Germany as Fallback
      type: i === 0 ? "start" : "intermediate",
    };
  });
  const lastStation = trains[trains.length - 1].endStation;
  stations?.push({
    label: lastStation?.name ?? "",
    lat: lastStation?.lat ?? 51.163361, // Center of Germany as Fallback
    lon: lastStation?.lon ?? 10.447683, // Center of Germany as Fallback
    type: "end",
  });
  return stations;
};
