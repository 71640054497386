import { IUser } from "@jozys/db-delay-types";
import { Box, InputProps, Typography } from "@mui/material";
import { uniq } from "lodash";
import React from "react";
import Avatar from "../../core/design-system/UserAvatar";
import GenericAddInput from "../design-system/GenericAddInput";
import useAuth from "../../auth/hooks/useAuth";

export type Passenger = {
  id: string;
  displayName: string;
};

export default function AddPassengers(
  props: InputProps & {
    onValueChange: (
      event: React.SyntheticEvent,
      value: Passenger | null
    ) => void;
    label: string;
    updatePassengers: (passengers: Passenger[]) => void;
    passengers: Passenger[];
  }
) {
  const auth = useAuth();
  const removePassenger = (index: number) => {
    const updatedPassengers = [...props.passengers];
    updatedPassengers.splice(index, 1);
    props.updatePassengers(updatedPassengers);
  };

  const getAutocompleteOptions = async (search: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/user/find?name=${search}`,
      { headers: { Authorization: `Bearer ${auth.getToken()}` } }
    );
    return (
      response.data
        .filter((v: IUser) => !props.passengers.map((p) => p.id).includes(v.id))
        .map((u: IUser) => ({ displayName: u.email, id: u.id })) ?? []
    );
  };

  return (
    <GenericAddInput<Passenger>
      getLabel={(p) => (p as Passenger)?.displayName ?? "loading"}
      getAutoCompleteOptions={getAutocompleteOptions}
      getKey={(p) => (p as Passenger)?.id ?? "1"}
      freeSolo={false}
      selected={props.passengers ?? []}
      onSelect={(v) => {
        if (v !== null) {
          console.log(v);
          props.passengers.push(v as Passenger);
          props.updatePassengers(uniq(props.passengers));
        }
      }}
      removeSelected={removePassenger}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component={"li"}
            sx={{ display: "flex", alignItems: "center" }}
            {...optionProps}
          >
            <Avatar key={option?.id} username={option?.displayName} />
            <Typography sx={{ ml: 1 }}>{option?.displayName}</Typography>
          </Box>
        );
      }}
    />
  );
}
