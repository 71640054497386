import { IJourneyPreview } from "@jozys/db-delay-types";
import { Container, Typography } from "@mui/material";
import JourneyPreview from "../../journey/components/Journey";
import { useNavigate } from "react-router-dom";
import LoadingWrapper from "../../core/components/LoadingWrapper";
import { useTranslation } from "react-i18next";

export default function LastJourney(props: {
  journey: IJourneyPreview | null;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <LoadingWrapper loading={props.journey == null}>
      <Container sx={{ p: 1 }}>
        <Typography textAlign="left" variant="h5">
          {t("home.last")}
        </Typography>
        {props.journey && (
          <JourneyPreview
            showIconButton
            showDate
            onClick={() =>
              navigate(`/journeys/${props.journey?.id}`, { state: { page: 1 } })
            }
            journey={props.journey}
          />
        )}
      </Container>
    </LoadingWrapper>
  );
}
