import { IJourneyDetailed, ITrainJourneyDetailed } from "@jozys/db-delay-types";
import { Check, Close, Error, FlagCircleOutlined, OpenInFull, Warning } from "@mui/icons-material";
import { Card, CardContent, Container, Modal, Tooltip, useTheme } from "@mui/material";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { parseSize } from "../../create-journey/utils/size";
import DelayInfo from "../design-system/DelayInfo";
import JourneyMap, { StationMarker } from "../design-system/JourneyMap";
import { IStation } from "@jozys/db-delay-types/src/stations/IStation";
import { getStations } from "../utils/getStationMarkers";
import { useState } from "react";

export interface DelayCardProps {
  journey?: IJourneyDetailed;
}

export default function DelayCard(props: DelayCardProps) {
  const { journey } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const [isMapOverlayOpen, setMapOverlay] = useState(false);

  const openModal = () => {
    setMapOverlay(true);
  }

  const closeModal = () => {
    setMapOverlay(false);
  }


  return (
    <Card sx={{ background: theme.palette.background.default, mt: 1 }}>
      <CardContent>
        <Container
          sx={{
            display: "flex",
            flexDirection: window.innerWidth < 700 ? "column" : "row",
          }}
        >
          <Container sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <DelayInfo
              text={`${t("journeys.delay.plannedArrival")}: ${moment(
                journey?.endDate
              )
                .format("dddd DD.MM.YYYY HH:mm")
                .toString()}`}
              icon={<FlagCircleOutlined />}
            />
            <DelayInfo
              text={`${t("journeys.delay.realArrival")}: ${moment(
                journey?.endDate
              )
                .add(journey?.delay, "minutes")
                .format("dddd DD.MM.YYYY HH:mm")
                .toString()}`}
              icon={
                <FlagCircleOutlined
                  color={journey && journey?.delay > 5 ? "error" : "success"}
                />
              }
            />
            <DelayInfo
              text={
                <>
                  {t("journeys.delay.title")}{" "}
                  <span
                    style={{
                      color:
                        journey && journey?.delay > 5
                          ? theme.palette.error.main
                          : theme.palette.success.main,
                      fontSize:
                        window.innerWidth < 500
                          ? parseSize("medium", 0.5)
                          : parseSize("medium", 0.75),
                      fontWeight: "bold",
                    }}
                  >
                    {journey?.delay}
                  </span>{" "}
                  {t("settings.minutes")}
                </>
              }
              icon={<Warning />}
            />
            <DelayInfo
              icon={
                journey && journey.delay > 5 ? (
                  <Error color="error" />
                ) : (
                  <Check color="success" />
                )
              }
              text={
                <Tooltip title={t("journeys.delay.dbDefinition")}>
                  <span>
                    {t("journeys.delay.onTime")}
                    {
                      <span style={{ fontWeight: "bold" }}>
                        {journey && journey.delay > 5
                          ? t("settings.no")
                          : t("settings.yes")}
                      </span>
                    }
                  </span>
                </Tooltip>
              }
            />
          </Container>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              marginTop: theme.spacing(2),
            }}

            onClick={openModal}
          >
            <JourneyMap stations={getStations(journey?.trains ?? [])} style={{ height: "30vh", width: "30vh" }} />

            <OpenInFull onClick={openModal} style={{alignSelf: "flex-end"}} />
          </div>
        </Container>
      </CardContent>

      <Modal
        open={isMapOverlayOpen}
        onClose={closeModal}
      >
        <div style={{ 
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center", 
          height: "100%",
          background: "hsla(0, 0%, 100%, 0.3)" 
          }}>
          
          <JourneyMap stations={getStations(journey?.trains ?? [])} style={{ height: "80vh", width: "80vw", border: "solid black 2px"}} />
          <Close onClick={closeModal} style={{fontSize: "3em", alignSelf: "flex-start", marginTop: "1em"}}/>
        </div>
      </Modal>
    </Card>
  );
}
