import { Container, Typography, useTheme } from "@mui/material";
import React from "react";
import IconText from "./IconText";
import { useTranslation } from "react-i18next";
import { Info } from "@mui/icons-material";

export default function Footer() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container
      maxWidth={false}
      sx={{
        bottom: 0,
        alignSelf: "flex-end",
        background: theme.palette.secondary.main,
        display: "flex",
        alignItems: "center",
        flexDirection: window.innerWidth < 500 ? "column" : "row",
        p: 4,
        clipPath:
          "polygon(50% 0%, 83% 12%, 100% 43%, 100% 100%, 68% 100%, 29% 100%, 0 100%, 0% 20%, 25% 4%);",
      }}
    >
      <Container maxWidth={false} sx={{ flex: 1, justifyItems: "flex-start" }}>
        <IconText
          icon={Info}
          size="small"
          color={theme.palette.getContrastText(theme.palette.secondary.main)}
          textAlign="left"
          text={t("registration.note")}
        />
      </Container>
      <Typography
        sx={{
          flex: 1,
          mt: 2,
          color: theme.palette.getContrastText(theme.palette.secondary.main),
        }}
        variant="h6"
        align="center"
      >
        © Copyright {new Date().getFullYear()} Joshua Slaar
      </Typography>
    </Container>
  );
}
