import { IJourneyDetailed } from "@jozys/db-delay-types";
import {
  Card,
  CardContent,
  Container,
  Divider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ConnectedDestinations from "../design-system/ConnectedDestinations";
import Iternary from "../design-system/Iternary";
import AddOthers from "./AddOther";
import AddPassengers, { Passenger } from "./AddPassengers";
import { DeepPartial } from "./CreateJourney";
import moment from "moment-timezone";
import StepHeader from "../design-system/StepHeader";

export interface ReviewJourneyProps {
  journey: DeepPartial<IJourneyDetailed> | undefined;
  addPassengers: (passengers: Passenger[]) => void;
  passengers: Passenger[];
  addOthers: (others: string[]) => void;
}

export default function ReviewJourney(props: ReviewJourneyProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <div>
      <Card sx={{ background: theme.palette.background.default }}>
        <StepHeader text={t("journeys.creation.steps.review")} />
        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          <ConnectedDestinations
            size="medium"
            //@ts-ignore
            arrivalTime={new Date(props.journey?.endDate?.toString())}
            from={props.journey?.startStation?.name ?? ""}
            to={props.journey?.endStation?.name ?? ""}
            //@ts-ignore
            departureTime={new Date(props.journey?.startDate?.toString())}
          />
          <Container>
            <Typography variant="h5" fontWeight={"bold"}>
              {
                //@ts-ignore
                moment(props.journey?.startDate)
                  .format("dddd DD.MM.YYYY")
                  .toString()
              }
            </Typography>
          </Container>
        </CardContent>
      </Card>
      <Card sx={{ mt: 1, background: theme.palette.background.default }}>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography textAlign={"left"} variant="h5" fontWeight={"bold"}>
            {t("journeys.creation.journey.othersAndPassengers")}
          </Typography>
          <Tooltip title="Passengers are users that are registered within this tool">
            <Typography textAlign={"left"} fontWeight={"bold"}>
              {t("journeys.creation.journey.passengers.title")}
            </Typography>
          </Tooltip>
          <AddPassengers
            //@ts-ignore
            passengers={props.passengers ?? []}
            label={t("journeys.creation.journey.passengers.title")}
            onValueChange={(_e, v) => {
              console.log(v);
            }}
            updatePassengers={props.addPassengers}
          />
          <Typography textAlign={"left"} color="info">
            {t("journeys.creation.journey.passengers.description")}
          </Typography>
          <Divider
            sx={{
              background: theme.palette.secondary.main,
              width: "10%",
              mt: 1,
              mb: 1,
              borderBottomWidth: 3,
            }}
          />
          <Typography textAlign={"left"} fontWeight={"bold"}>
            {t("journeys.creation.journey.others.title")} {}
          </Typography>
          <Typography
            style={{
              color: theme.palette.secondary.main,
              width: window.innerWidth < 500 ? "80%" : "30%",
            }}
          >
            {t("journeys.creation.journey.others.description")}
          </Typography>
          <AddOthers
            //@ts-ignore
            others={props.journey?.others ?? []}
            addOthers={props.addOthers}
            label={t("journeys.creation.journey.others.title")}
            onValueChange={(_e, v) => {
              console.log(v);
            }}
          />
        </CardContent>
      </Card>
      <Card sx={{ background: "transparent" }}>
        <Iternary journey={props.journey as IJourneyDetailed} />
      </Card>
    </div>
  );
}
