import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import Button from "../../core/design-system/Button";
import { useTranslation } from "react-i18next";

export default function UserAccountSettings() {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: window.innerWidth < 500 ? "90%" : "50%",
        background: theme.palette.background.default,
      }}
    >
      <CardHeader title={t("settings.users.title")} />
      <CardContent>
        <Typography textAlign={"center"}>
          {t("settings.users.description")}
        </Typography>
        <Button
          sx={{ mt: 1 }}
          color="secondary"
          variant="contained"
          onClick={() =>
            window.location.assign(
              `${process.env.REACT_APP_KC_URL}/realms/${process.env.REACT_APP_KC_REALM}/account`
            )
          }
        >
          {t("settings.users.button")}
        </Button>
      </CardContent>
    </Card>
  );
}
