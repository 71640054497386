import React, { ReactNode } from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";

export interface ButtonProps extends MuiButtonProps {
  children?: ReactNode | ReactNode[];
}

export default function Button(props: ButtonProps) {
  return <MuiButton {...props}>{props.children}</MuiButton>;
}
