import {
  DirectionsRailway,
  Functions,
  Info,
  People,
  Person,
  RailwayAlert,
  Timelapse,
  TimelapseSharp,
  Warning,
} from "@mui/icons-material";
import { Container, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import LoadingWrapper from "../../core/components/LoadingWrapper";
import { getPunctialityColor } from "../../core/utils/statistics";
import StatisticCard from "../../home/design-system/StatisticCard";
import { IStatistic } from "../../home/interface/IStatistic";
import useStatistics from "../hooks/useStatistics";
import IconText from "../../core/design-system/IconText";

export default function Statistics() {
  const { detailedStatistics, getDetailedStatistics } = useStatistics();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { t } = useTranslation();
  const theme = useTheme();
  const stats: IStatistic<string>[] = [
    {
      key: t("statistics.punctuality.title"),
      value: `${detailedStatistics.punctuality}%`,
      //@ts-ignore
      icon: (
        <Timelapse
          sx={{
            color: getPunctialityColor(
              detailedStatistics.punctuality,
              theme.palette
            ),
          }}
        />
      ),
      color: getPunctialityColor(detailedStatistics.punctuality, theme.palette),
    },
    {
      key: t("statistics.punctuality.alone"),
      value: `${detailedStatistics.punctualityAlone.toString()}%`,
      //@ts-ignore
      icon: (
        <Person
          sx={{
            color: getPunctialityColor(
              detailedStatistics.punctualityAlone,
              theme.palette
            ),
            fontSize: 40,
          }}
        />
      ),
      color: getPunctialityColor(
        detailedStatistics.punctualityAlone,
        theme.palette
      ),
    },
    {
      key: t("statistics.punctuality.together"),
      value: `${detailedStatistics.punctualityTogether.toString()}%`,
      //@ts-ignore
      icon: (
        <People
          sx={{
            color: getPunctialityColor(
              detailedStatistics.punctualityTogether,
              theme.palette
            ),
            fontSize: 40,
          }}
        />
      ),
      color: getPunctialityColor(
        detailedStatistics.punctualityTogether,
        theme.palette
      ),
    },
    {
      key: t("statistics.journeys.title"),
      value: detailedStatistics.amountOfJourneys?.toString() ?? 0,
      icon: <DirectionsRailway sx={{ fontSize: 40 }} />,
    },

    {
      key: t("statistics.journeys.timeOnTrain"),
      value: `${detailedStatistics.timeOnTrain}`,
      icon: <TimelapseSharp sx={{ fontSize: 40 }} />,
    },
    {
      key: t("statistics.delay.sum"),
      value: `${detailedStatistics.sumOfDelay}`,
      icon: <Functions sx={{ fontSize: 40 }} />,
    },
    {
      key: t("statistics.delay.average"),
      value: `${detailedStatistics.averageDelay.minutes}min ${detailedStatistics.averageDelay.seconds}s`,
      icon: <RailwayAlert sx={{ fontSize: 40 }} />,
      color:
        detailedStatistics.averageDelay.minutes > 5
          ? theme.palette.error.main
          : theme.palette.success.main,
    },
    {
      key: t("statistics.delay.highest"),
      value: `${detailedStatistics.highestDelay}min`,
      icon: (
        <Warning
          color={detailedStatistics.highestDelay > 5 ? "error" : "secondary"}
          sx={{ fontSize: 40 }}
        />
      ),
      color:
        detailedStatistics.highestDelay > 5
          ? theme.palette.error.main
          : theme.palette.success.main,
    },
    {
      key: t("statistics.journeys.titleAlone"),
      value: `${detailedStatistics.journeysAlone}`,
      icon: <Person sx={{ fontSize: 40 }} />,
    },
    {
      key: t("statistics.journeys.titleShared"),
      value: `${detailedStatistics.journeysTogether}`,
      icon: <People sx={{ fontSize: 40 }} />,
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    getDetailedStatistics().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth={false} sx={{ width: "100%", p: 0, m: 0 }}>
      <Typography color={"textPrimary"} variant="h3">
        {t("statistics.title")}
      </Typography>

      <LoadingWrapper loading={loading}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            color="textPrimary"
            textAlign={"left"}
            variant="h6"
            fontWeight={"bold"}
            sx={{ ml: 1 }}
          >
            {t("statistics.generic")}
          </Typography>
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              alignContent: "space-between",
              alignSelf: "center",
              width: "80%",
            }}
          >
            {stats.map((stat: IStatistic<string>) => (
              <div
                style={{
                  flex: 1,
                  marginTop: theme.spacing(2),
                  flexBasis: "25%",
                }}
              >
                <StatisticCard statistic={stat} />
              </div>
            ))}
          </div>
          <Container sx={{ mt: 1 }}>
            <Typography
              color="textPrimary"
              textAlign={"left"}
              variant="h6"
              fontWeight={"bold"}
            >
              {t("statistics.detailed")}
            </Typography>
            <IconText
              text="This will be implemented in the future."
              icon={Info}
            />
          </Container>
        </div>
      </LoadingWrapper>
    </Container>
  );
}
