import { IDetailedStatistics, ISimpleStatistics } from "@jozys/db-delay-types";

export type StatisticsContextType = {
  simpleStatistics: ISimpleStatistics;
  getSimpleStatistics: () => Promise<ISimpleStatistics>;
  detailedStatistics: IDetailedStatistics;
  getDetailedStatistics: () => Promise<IDetailedStatistics>;
};
export const StatisticsContextDefault: StatisticsContextType = {
  simpleStatistics: {
    amountOfJourneys: 0,
    punctuality: 0,
    highestDelay: 0,
    averageDelay: {
      minutes: 0,
      seconds: 0,
    },
  },
  detailedStatistics: {
    amountOfJourneys: 0,
    punctuality: 0,
    highestDelay: 0,
    averageDelay: {
      minutes: 0,
      seconds: 0,
    },
    sumOfDelay: "",
    journeysAlone: 0,
    journeysTogether: 0,
    punctualityAlone: 0,
    punctualityTogether: 0,
  },
  getDetailedStatistics: () => new Promise(() => null),
  getSimpleStatistics: () => new Promise(() => null),
};
