import { CardHeader, Divider, Typography, useTheme } from "@mui/material";
import React from "react";

export default function StepHeader(props: { text: string }) {
  const theme = useTheme();
  return (
    <CardHeader
      title={
        <Typography variant="h6" textAlign={"left"} color="textPrimary">
          {props.text}
        </Typography>
      }
      subheader={
        <Divider
          sx={{
            background: theme.palette.secondary.main,
            width: "10%",
            mt: 1,
            borderBottomWidth: 3,
          }}
        />
      }
    ></CardHeader>
  );
}
