export type Size = "small" | "medium" | "large";
export function parseSize(size: Size, multiplicator = 1) {
  switch (size) {
    case "large":
      return 2 * multiplicator + "em";
    case "medium":
      return 1.5 * multiplicator + "em";
    case "small":
      return 1 * multiplicator + "em";
    default:
      return 1.5 * multiplicator + "em";
  }
}
