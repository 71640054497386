import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import useUser from "../../auth/hooks/useUser";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../design-system/LanguageSwitch";

export default function LanguageSettings() {
  const user = useUser();
  const { t } = useTranslation();
  const theme = useTheme();

  React.useEffect(() => {}, [user.user?.language]);
  return (
    <Card
      sx={{
        width: window.innerWidth < 500 ? "90%" : "50%",
        background: theme.palette.background.default,
      }}
    >
      <CardHeader title={t("settings.language.title")} />
      <CardContent>
        <Typography sx={{ textWrap: "balance" }} textAlign={"center"}>
          {t("settings.language.description")}
        </Typography>
        <LanguageSwitch defaultValue={user.user?.language ?? "en"} />
      </CardContent>
    </Card>
  );
}
