import { Container, Theme, Typography, useTheme } from "@mui/material";
import React from "react";
import { parseSize, Size } from "../../create-journey/utils/size";

export default function IconText(props: {
  text: string;
  icon: React.ElementType;
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "warning"
    | "info"
    | "success"
    | string;
  size?: Size | null;
  textAlign?: "center" | "left" | "right";
}) {
  const theme = useTheme();

  const getColor = (
    props: { color?: keyof Theme["palette"] | string },
    theme: Theme
  ) => {
    if (props.color) {
      // Check if props.color is a key in theme.palette
      if (props.color in theme.palette) {
        //@ts-ignore
        return theme.palette[props.color as keyof Theme["palette"]].main;
      } else {
        return props.color;
      }
    }
    return theme.palette.text.primary;
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: window.innerHeight < 200 ? "row" : "column",
        alignItems: "center",
        minHeight: 125,
        justifyContent: "center",
      }}
      maxWidth={false}
    >
      <props.icon
        sx={{
          fontSize: parseSize(props.size ?? "medium", 1.25),
          color: getColor(props, theme),
        }}
      />
      <Typography
        textAlign={props.textAlign ?? "center"}
        sx={{
          marginTop: 2,
          width: window.innerWidth < 500 ? "100%" : "50%",
          alignSelf: "center",
          fontSize: parseSize(props.size ?? "medium"),
          color: getColor(props, theme),
        }}
      >
        {props.text}
      </Typography>
    </Container>
  );
}
