import {
  Connection,
  Train as ITrain,
} from "@jozys/db-api-wrapper/dist/src/types/connection";
import { IDelayData } from "@jozys/db-delay-types";
import { RailwayAlert } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import DetailedTrainInformation from "../design-system/DetailedTrainInformation";
import StepHeader from "../design-system/StepHeader";
import Transition from "../design-system/Transition";

export interface AddDelayDataProps {
  handleDelayData: (delayData: IDelayData, trainIndex: number) => void;
  setDelay: (delay: number) => void;
  connection: Connection | undefined;
}

export default function AddDelayData(props: AddDelayDataProps) {
  const getEndDate = () => {
    // eslint-disable-next-line no-lone-blocks
    {
      const lastElement =
        props.connection!.trains[props.connection!.trains.length - 1];

      return moment(lastElement.arrivalTime).add(
        lastElement.delay?.delay,
        "minutes"
      );
    }
  };
  const [realArrival, setRealArrival] = React.useState<moment.Moment | null>(
    getEndDate()
  );
  const filteredTrains = props.connection?.trains.filter(
    (t) => t.category.toLowerCase() !== "sonstige"
  );

  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.default,
      }}
    >
      <StepHeader text={t("journeys.creation.delay.info")} />
      <CardContent>
        {props.connection &&
          filteredTrains &&
          filteredTrains.map((train: ITrain, i) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginTop: theme.spacing(1) }}>
                <DetailedTrainInformation
                  elevation={0}
                  handleDelayEdit={(data) => props.handleDelayData(data, i)}
                  train={train}
                />
              </div>
              {filteredTrains.length > 0 && i <= filteredTrains.length - 2 && (
                <div style={{ width: "70%", alignSelf: "center" }}>
                  <Transition
                    nextDepartureTime={filteredTrains[i + 1].departureTime}
                    previousArrivalTime={train.arrivalTime}
                  />
                </div>
              )}
            </div>
          ))}
        <Typography variant="h5">
          {t("journeys.creation.delay.arrival")}
        </Typography>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pb: 1,
          }}
        >
          <RailwayAlert fontSize="large" color={"disabled"} />
          <DateTimePicker
            sx={{ ml: 1 }}
            value={realArrival}
            ampm={false}
            minDateTime={moment(props.connection?.endDate)}
            onChange={(val) => {
              setRealArrival(val);
              props.setDelay(
                Math.abs(moment(props.connection?.endDate).diff(val, "minutes"))
              );
            }}
          />
        </Container>
        <Typography sx={{ pb: 1 }}>
          {t("journeys.creation.delay.delayText")}{" "}
          {Math.abs(
            moment(props.connection?.endDate).diff(realArrival, "minutes")
          )}{" "}
          {t("settings.minutes")}
        </Typography>
      </CardContent>
    </Card>
  );
}
