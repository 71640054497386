import { IJourneyDetailed } from "@jozys/db-delay-types";
import { Delete, Edit } from "@mui/icons-material";
import {
  Card,
  CardHeader,
  Chip,
  Container,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import ConnectedDestinations from "../../create-journey/design-system/ConnectedDestinations";
import moment from "moment-timezone";
import { stringToColor } from "../../core/design-system/UserAvatar";
import { useTranslation } from "react-i18next";
import { parseSize } from "../../create-journey/utils/size";

export default function JourneyHeader(props: { journey: IJourneyDetailed }) {
  const { journey } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Card sx={{ p: 1, background: theme.palette.background.default, mt: 1 }}>
      <CardHeader
        action={
          <Container>
            <IconButton>
              <Edit
                color="secondary"
                onClick={() => window.alert("Not implemented yet")}
              />
            </IconButton>
            <IconButton>
              <Delete
                color="error"
                onClick={() => window.alert("Not implemented yet")}
              />
            </IconButton>
          </Container>
        }
      ></CardHeader>
      {journey && (
        <ConnectedDestinations
          size={window.innerWidth < 600 ? "medium" : "large"}
          arrivalTime={journey.endDate}
          departureTime={journey.startDate}
          from={journey.startStation.name}
          to={journey.endStation.name}
        />
      )}
      <Container>
        <Typography
          sx={{
            fontSize: parseSize(window.innerWidth < 600 ? "small" : "medium"),
          }}
          fontWeight={"bold"}
        >
          {moment(journey?.startDate).format("dddd DD.MM.YYYY").toString()}
        </Typography>
      </Container>

      <Container>
        {t("journeys.detailed.passengers")}:{" "}
        {journey?.passengers?.map((p) => (
          <Chip
            onClick={() => alert("Clicked user profile")}
            sx={{
              background: stringToColor(p.username),
              color: theme.palette.getContrastText(stringToColor(p.username)),
              ml: 1,
            }}
            label={p.firstname + " " + p.lastname}
          />
        )) ?? ""}
      </Container>
      {journey?.others && journey.others.length > 0 && (
        <Typography sx={{ mt: 1 }}>
          {t("journeys.detailed.others")}:{" "}
          {journey?.others?.map((o) => (
            <Chip
              sx={{
                background: stringToColor(o),
                ml: 1,
                color: theme.palette.getContrastText(stringToColor(o)),
              }}
              label={o}
            />
          )) ?? ""}
        </Typography>
      )}
    </Card>
  );
}
