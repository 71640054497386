export function mapStringToDelay(delayReason: string | undefined) {
  if (delayReason === undefined || delayReason === null) return null;
  const parsedString = replaceGermanLetters(delayReason);
  return delayReasons.find(
    (val) => val.name.toLowerCase() === parsedString.toLowerCase()
  );
}

function replaceGermanLetters(str: string): string {
  const replacements: { [key: string]: string } = {
    ä: "ae",
    ö: "oe",
    ü: "ue",
    ß: "ss",
    Ä: "Ae",
    Ö: "Oe",
    Ü: "Ue",
  };

  return str
    .replaceAll(/[äöüßÄÖÜ]/g, (match) => replacements[match])
    .replaceAll("_", " ");
}
export const delayReasons = [
  {
    id: "Polizeieinsatz",
    name: "Polizeieinsatz",
  },
  {
    id: "Feuerwehreinsatz_auf_der_Strecke",
    name: "Feuerwehreinsatz auf der Strecke",
  },
  {
    id: "aerztliche_Versorgung_eines_Fahrgastes",
    name: "aerztliche Versorgung eines Fahrgastes",
  },
  {
    id: "unbefugtes_Ziehen_der_Notbremse",
    name: "unbefugtes Ziehen der Notbremse",
  },
  {
    id: "unbefugte_Personen_auf_der_Strecke",
    name: "unbefugte Personen auf der Strecke",
  },
  {
    id: "Notarzteinsatz_auf_der_Strecke",
    name: "Notarzteinsatz auf der Strecke",
  },
  {
    id: "Streikauswirkungen",
    name: "Streikauswirkungen",
  },
  {
    id: "Tiere_auf_der_Strecke",
    name: "Tiere auf der Strecke",
  },
  {
    id: "Unwetter",
    name: "Unwetter",
  },
  {
    id: "Warten_auf_ein_verspaetetes_Schiff",
    name: "Warten auf ein verspaetetes Schiff",
  },
  {
    id: "Pass__und_Zollkontrolle",
    name: "Pass  und Zollkontrolle",
  },
  {
    id: "technischer_Defekt_am_Bahnhof",
    name: "technischer Defekt am Bahnhof",
  },
  {
    id: "Beeintraechtigung_durch_Vandalismus",
    name: "Beeintraechtigung durch Vandalismus",
  },
  {
    id: "Entschaerfung_einer_Fliegerbombe",
    name: "Entschaerfung einer Fliegerbombe",
  },
  {
    id: "Beschaedigung_einer_Bruecke",
    name: "Beschaedigung einer Bruecke",
  },
  {
    id: "umgestuerzter_Baum_auf_der_Strecke",
    name: "umgestuerzter Baum auf der Strecke",
  },
  {
    id: "Unfall_an_einem_Bahnuebergang",
    name: "Unfall an einem Bahnuebergang",
  },
  {
    id: "Warten_auf_Anschlussreisende",
    name: "Warten auf Anschlussreisende",
  },
  {
    id: "Witterungsbedingte_Beeintraechtigungen",
    name: "Witterungsbedingte Beeintraechtigungen",
  },
  {
    id: "Verspaetung_im_Ausland",
    name: "Verspaetung im Ausland",
  },
  {
    id: "Bereitstellung_weiterer_Wagen",
    name: "Bereitstellung weiterer Wagen",
  },
  {
    id: "Abhaengen_von_Wagen",
    name: "Abhaengen von Wagen",
  },
  {
    id: "Gegenstaende_auf_der_Strecke",
    name: "Gegenstaende auf der Strecke",
  },
  {
    id: "Bauarbeiten",
    name: "Bauarbeiten",
  },

  {
    id: "Reparatur_an_der_Oberleitung",
    name: "Reparatur an der Oberleitung",
  },
  {
    id: "Reparatur_an_einem_Signal",
    name: "Reparatur an einem Signal",
  },
  {
    id: "Streckensperrung",
    name: "Streckensperrung",
  },
  {
    id: "Reparatur_am_Zug",
    name: "Reparatur am Zug",
  },
  {
    id: "Reparatur_an_der_Strecke",
    name: "Reparatur an der Strecke",
  },
  {
    id: "defektes_Stellwerk",
    name: "defektes Stellwerk",
  },
  {
    id: "technischer_Defekt_an_einem_Bahnuebergang",
    name: "technischer Defekt an einem Bahnuebergang",
  },
  {
    id: "voruebergehend_verminderte_Geschwindigkeit_auf_der_Strecke",
    name: "voruebergehend verminderte Geschwindigkeit auf der Strecke",
  },
  {
    id: "Verspaetung_eines_vorausfahrenden_Zuges",
    name: "Verspaetung eines vorausfahrenden Zuges",
  },
  {
    id: "Warten_auf_einen_entgegenkommenden_Zug",
    name: "Warten auf einen entgegenkommenden Zug",
  },
  {
    id: "Vorfahrt_eines_anderen_Zuges",
    name: "Vorfahrt eines anderen Zuges",
  },
  {
    id: "verspaetete_Bereitstellung_des_Zuges",
    name: "verspaetete Bereitstellung des Zuges",
  },
  {
    id: "Verspaetung_aus_vorheriger_Fahrt",
    name: "Verspaetung aus vorheriger Fahrt",
  },
  {
    id: "kurzfristiger_Personalausfall",
    name: "kurzfristiger Personalausfall",
  },
  {
    id: "kurzfristige_Erkrankung_von_Personal",
    name: "kurzfristige Erkrankung von Personal",
  },
  {
    id: "verspaetetes_Personal_aus_vorheriger_Fahrt",
    name: "verspaetetes Personal aus vorheriger Fahrt",
  },
  {
    id: "Streik",
    name: "Streik",
  },
  {
    id: "Unwetterauswirkungen",
    name: "Unwetterauswirkungen",
  },
  {
    id: "Verfuegbarkeit_der_Gleise_derzeit_eingeschraenkt",
    name: "Verfuegbarkeit der Gleise derzeit eingeschraenkt",
  },
  {
    id: "technischer_Defekt_an_einem_anderen_Zug",
    name: "technischer Defekt an einem anderen Zug",
  },
  {
    id: "zusaetzlicher_Halt_zum_Ein_und_Ausstieg",
    name: "zusaetzlicher Halt zum Ein und Ausstieg",
  },
  {
    id: "Umleitung_des_Zuges",
    name: "Umleitung des Zuges",
  },
  {
    id: "Schnee_und_Eis",
    name: "Schnee und Eis",
  },
  {
    id: "witterungsbedingt_verminderte_Geschwindigkeit",
    name: "witterungsbedingt verminderte Geschwindigkeit",
  },
  {
    id: "defekte_Tuer",
    name: "defekte Tuer",
  },
  {
    id: "behobener_technischer_Defekt_am_Zug",
    name: "behobener technischer Defekt am Zug",
  },
  {
    id: "technische_Untersuchung_am_Zug",
    name: "technische Untersuchung am Zug",
  },
  {
    id: "Reparatur_an_einer_Weiche",
    name: "Reparatur an einer Weiche",
  },
  {
    id: "Erdrutsch",
    name: "Erdrutsch",
  },
  {
    id: "Hochwasser",
    name: "Hochwasser",
  },
  {
    id: "behoerdliche_Massnahme",
    name: "behoerdliche Massnahme",
  },
  {
    id: "Streik_eines_anderen_EVU",
    name: "Streik eines anderen EVU",
  },
  {
    id: "Keine_Verspaetungsbegruendung",
    name: "Keine Verspaetungsbegruendung",
  },
  {
    id: "Naehere_Informationen_in_Kuerze",
    name: "Naehere Informationen in Kuerze",
  },
  {
    id: "Betriebsstabilisierung",
    name: "Betriebsstabilisierung",
  },
  {
    id: "Technische_Stoerung_am_Bus",
    name: "Technische Stoerung am Bus",
  },
  {
    id: "Ersatzverkehr_mit_Bus_ist_eingerichtet",
    name: "Ersatzverkehr mit Bus ist eingerichtet",
  },
  {
    id: "Laengere_Haltezeit_am_Bahnhof",
    name: "Laengere Haltezeit am Bahnhof",
  },
  {
    id: "Technische_Stoerung_am_Zug",
    name: "Technische Stoerung am Zug",
  },
  {
    id: "Kurzfristiger_Fahrzeugausfall",
    name: "Kurzfristiger Fahrzeugausfall",
  },
  {
    id: "Stau__Hohes_Verkehrsaufkommen",
    name: "Stau / Hohes Verkehrsaufkommen",
  },
  {
    id: "hohes_Fahrgastaufkommen_verlaengert_Ein_und_Ausstieg",
    name: "hohes Fahrgastaufkommen verlaengert den Ein- und Ausstieg",
  },
  {
    id: "Zug_verkehrt_mit_verminderter_Geschwindigkeit",
    name: "Zug verkehrt mit verminderter Geschwindigkeit",
  },
  {
    id: "Verzoegerungen_im_Betriebsablauf",
    name: "Verzoegerungen im Betriebsablauf",
  },
];
