import {
  IJourneyDetailed,
  IJourneyPreview,
  ITrainJourney,
  ITrainJourneyPreview,
} from "@jozys/db-delay-types";

export const _reverseInJourneys = (journeys: IJourneyPreview[]) => {
  return journeys.map((j) => _reverseJourney(j));
};

export const _reverseJourney = (
  j: IJourneyPreview | IJourneyDetailed
): IJourneyPreview | IJourneyDetailed => {
  return {
    ...j,
    startStation: _reverseString(j.startStation),
    endStation: _reverseString(j.endStation),
    trains: j.trains.map((t) => _reverseTrain(t) as ITrainJourneyPreview),
  };
};

export const _reverseTrain = (
  t: ITrainJourneyPreview | ITrainJourney
): ITrainJourneyPreview | ITrainJourney => {
  return {
    ...t,
    destination: _reverseString(t.destination ?? ""),
    operator: _reverseString(t.operator ?? ""),
  };
};

const _reverseString = (s: string) => {
  return s.split("").reverse().join("");
};
