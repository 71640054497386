import {
  ArrowRightAlt,
  FlagCircle,
  PlayCircleFilled,
} from "@mui/icons-material";
import { parseSize, Size } from "../utils/size";
import Destination from "./Destination";

export interface ConnectedDestionansProps {
  departureTime: Date;
  from: string;
  arrivalTime: Date;
  to: string;
  size: Size;
}

export default function ConnectedDestinations(props: ConnectedDestionansProps) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Destination
        icon={PlayCircleFilled}
        time={props.departureTime}
        title={props.from}
        size={props.size}
      />
      <ArrowRightAlt
        color="secondary"
        sx={{ fontSize: parseSize(props.size) }}
      />
      <Destination
        icon={FlagCircle}
        time={props.arrivalTime}
        title={props.to}
        size={props.size}
      />
    </div>
  );
}
