import React from "react";
import { Theme } from "@mui/material";
import { ThemeProvider as MaterialProvider } from "@mui/material/styles";
import { default as myTheme } from "../theme";
import { ThemeContext, ThemeContextDefault } from "../interfaces/ThemeContext";

export interface ThemeProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export const ThemeSwitchContext =
  React.createContext<ThemeContext>(ThemeContextDefault);

export default function ThemeProvider(props: ThemeProviderProps) {
  const { children } = props;
  const getUserDefaultTheme = (): boolean => {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  };

  const [isDark, setIsDark] = React.useState<boolean>(
    localStorage.getItem("isDark")
      ? localStorage.getItem("isDark") === "true"
        ? true
        : false
      : getUserDefaultTheme()
  );
  const [theme, setTheme] = React.useState<Theme>(myTheme(isDark ?? false));

  const toggleTheme = () => {
    localStorage.setItem("isDark", JSON.stringify(!isDark));

    const newTheme = myTheme(!isDark);
    setTheme(newTheme);
    setIsDark(!isDark);
  };
  return (
    <ThemeSwitchContext.Provider value={{ isThemeDark: isDark, toggleTheme }}>
      <MaterialProvider theme={theme}>{children}</MaterialProvider>
    </ThemeSwitchContext.Provider>
  );
}
