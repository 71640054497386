import { Card, Container, Typography, useTheme } from "@mui/material";
import { IStatistic } from "../interface/IStatistic";
import { parseSize } from "../../create-journey/utils/size";

export interface StatisticCardProps {
  statistic: IStatistic<string>;
  onClick?: () => void;
}

export default function StatisticCard(props: StatisticCardProps) {
  const theme = useTheme();
  return (
    <Card
      onClick={props.onClick}
      sx={{
        background: theme.palette.background.default,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        minHeight: 120,
        minWidth: 120,
        maxHeight: 200,
        maxWidth: 200,
        height: "16vw",
        width: "16vw",
        borderRadius: theme.spacing(3),
      }}
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyItems: "center",
          flex: 4,
        }}
      >
        <Typography
          sx={{
            flex: 2,
            flexWrap: "wrap",
            overflowWrap: "break-word",
            textWrap: "pretty",
            whiteSpace: "wrap",
            maxWidth: "60%",
            fontSize:
              window.innerWidth < 600
                ? parseSize("medium", 0.5)
                : parseSize("medium", 0.75),
          }}
          textAlign={"center"}
          color={"textPrimary"}
          fontWeight="bold"
          variant="subtitle1"
        >
          {props.statistic.key}
        </Typography>
        <Container
          sx={{
            display: "flex",
            flex: 4,
            alignSelf: "center",
            justifySelf: "center",
          }}
        >
          {props.statistic.icon}
        </Container>
      </Container>
      <Typography
        variant="h6"
        sx={{
          flex: 1,
          color: props.statistic.color ?? theme.palette.text.primary,
          fontWeight: "bold",
          fontSize:
            window.innerWidth < 600
              ? parseSize("medium", 0.5)
              : parseSize("medium", 1),
        }}
      >
        {props.statistic.value}
      </Typography>
    </Card>
  );
}
