import { createTheme, Theme } from "@mui/material";
import { deDE } from "@mui/x-date-pickers/locales";
import "@fontsource/titillium-web";
import "@fontsource/bungee";

const theme = (dark: boolean): Theme => {
  return createTheme(
    {
      palette: {
        primary: {
          light: "#89231F",
          main: "#89231F",
          dark: "#884E49",
        },
        secondary: {
          main: "#1f8589",
        },
        tonalOffset: {
          light: 0.55,
          dark: 0.4,
        },
        background: dark
          ? {
              paper: "#2a2a2a",
              default: "#3f3f3f",
            }
          : {
              default: "#f5f5f5",
              paper: "#dfdfdf",
            },
        success: {
          main: dark ? "#518912" : "#508B2B",
        },
        error: {
          main: dark ? "#f98c8f" : "#EC0016",
        },
        mode: dark ? "dark" : "light",
      },
      typography: {
        fontFamily: "Titillium Web, Bungee",
        allVariants: {
          color: dark ? "#dfdfdf" : "#282d37",
        },
      },
    },
    deDE
  );
};
export default theme;
