import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useUser from "../../auth/hooks/useUser";

export default function LanguageSwitch(props: { defaultValue: string }) {
  const languages = ["en", "de", "fr", "ed", "bd", "nl", "lb", "sß"];
  const { t } = useTranslation();
  const { updateUser, user } = useUser();
  return (
    <Select
      onChange={(e) => {
        if (user) {
          updateUser({ ...user, language: e.target.value ?? "en" });
        }
      }}
      value={props.defaultValue}
    >
      {languages.map((language) => (
        <MenuItem key={language} value={language}>
          {t(`settings.language.${language}`)}
        </MenuItem>
      ))}
    </Select>
  );
}
