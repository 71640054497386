import {
  LoginOutlined,
  MenuOutlined,
  TrainOutlined,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  MenuItem,
  Typography,
  useTheme,
  Drawer,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../auth/hooks/useAuth";
import useUser from "../../auth/hooks/useUser";
import AvatarMenu from "../components/AvatarMenu";
import ThemeSwitcher from "../theme/components/ThemeSwitcher";
import { navigateHome } from "../utils/helper";
import Button from "./Button";
import UserAvatar from "./UserAvatar";
export default function Menu() {
  const [profileAnchor, setProfileAnchor] = useState<
    HTMLDivElement | undefined
  >();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const { user } = useUser();
  const pages = ["Statistics", "Journeys"];
  return (
    <AppBar
      position="static"
      style={{
        background: theme.palette.primary.main,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuOutlined />
            </IconButton>
            <Drawer
              id="menu-appbar"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    handleCloseNavMenu();
                    if (auth.getToken() == null) {
                      auth.signIn();
                    } else {
                      navigate(page.toLowerCase());
                    }
                  }}
                >
                  <Typography sx={{ textAlign: "center" }}>{page}</Typography>
                </MenuItem>
              ))}
            </Drawer>
          </Box>
          <Button
            onClick={navigateHome}
            sx={{
              mr: 2,
              display: { xs: "flex" },
              flexGrow: 0,
              fontFamily: "monospace",
              fontWeight: 500,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
            startIcon={
              <TrainOutlined sx={{ display: { xs: "flex" }, mr: 1 }} />
            }
          >
            <Typography
              sx={{ color: "#dfdfdf" }}
              variant="h5"
              noWrap
              component="a"
            >
              DB Delay
            </Typography>
          </Button>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  if (auth.getToken() == null) {
                    auth.signIn();
                  } else {
                    navigate(page.toLowerCase());
                  }
                }}
                sx={{ my: 2, color: "#dfdfdf", display: "block" }}
              >
                {t([`home.pages.${page.toLowerCase()}`, "Fail"])}
              </Button>
            ))}
          </Box>

          <Box sx={{ mr: 2 }}>
            <ThemeSwitcher />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {auth.getToken() ? (
              <>
                <UserAvatar
                  onClick={(event) =>
                    setProfileAnchor(event?.currentTarget ?? null)
                  }
                  firstname={user?.firstname}
                  lastname={user?.lastname}
                  username={user?.username ?? "user"}
                />
                <AvatarMenu
                  anchorElement={profileAnchor}
                  handleClose={() => setProfileAnchor(undefined)}
                />
              </>
            ) : (
              <Button
                sx={{
                  my: 2,
                  color: "#dfdfdf",
                  display: "flex",
                  flexDirection: "row",
                  justifySelf: "end",
                }}
                onClick={() => auth.signIn()}
                startIcon={<LoginOutlined />}
              >
                {t("settings.login")}
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
