import { CircularProgress, Container } from "@mui/material";
import React from "react";

export interface LoadingWrapperProps {
  loading: boolean;
  children: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
}

export default function LoadingWrapper(props: LoadingWrapperProps) {
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        ...props.style,
      }}
    >
      {!props.loading ? (
        props.children
      ) : (
        <Container sx={{ flex: 1 }}>
          <CircularProgress />
        </Container>
      )}
    </div>
  );
}
