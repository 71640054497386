import { Redo } from "@mui/icons-material";
import { Container, Typography, useTheme } from "@mui/material";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

export interface TransitionProps {
  previousArrivalTime: Date;
  nextDepartureTime: Date;
}

export default function Transition(props: TransitionProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        p: 1,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Redo />
      <Typography sx={{ ml: 1 }} variant="h6" fontWeight={"bold"}>
        {t("journeys.change.title")}{" "}
        {Math.abs(
          moment(props.previousArrivalTime).diff(
            props.nextDepartureTime,
            "minutes"
          )
        )}{" "}
        min
      </Typography>
    </Container>
  );
}
