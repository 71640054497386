import React from "react";
import { Timeline } from "@mui/lab";
import CustomizedTimelineItem from "../../core/components/CustomTimelineItem";
import useThemeSwitch from "../../core/theme/hooks/useThemeSwitch";
import DB_Stats_Light from "../../../assets/db_stats_light.png";
import DB_Stats_Dark from "../../../assets/db_stats_dark.png";

import DB_Search_Light from "../../../assets/db_search_light.png";
import DB_Search_Dark from "../../../assets/db_search_dark.png";
import { useTranslation } from "react-i18next";
export default function ProductTimeline() {
  const theme = useThemeSwitch();
  const { t } = useTranslation();
  return (
    <Timeline position="alternate">
      <CustomizedTimelineItem
        key={"1"}
        title={t("registration.timeline.0.title")}
        description={t("registration.timeline.0.description")}
        image={theme.isThemeDark ? DB_Stats_Dark : DB_Stats_Light}
        direction="normal"
        rotateImage
      />
      <CustomizedTimelineItem
        key={"2"}
        title={t("registration.timeline.1.title")}
        description={t("registration.timeline.1.description")}
        image={theme.isThemeDark ? DB_Search_Dark : DB_Search_Light}
        direction="opposite"
        rotateImage
        containerStyle={{ paddingBottom: "2vh" }}
      />
    </Timeline>
  );
}
