import { Train } from "@jozys/db-api-wrapper/dist/src/types/connection";
import {
  Autocomplete,
  Container,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useState } from "react";
import { delayReasons, mapStringToDelay } from "../utils/delayReasons";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";

export interface DelayInputProps {
  train: Train;
  readOnly?: boolean;
  handleChange: (realArrivalTime: moment.Moment, reason: DelayReason) => void;
}

export type DelayReason = {
  id: string;
  name: string;
};

export default function DelayInput(props: DelayInputProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [realArrival, setRealArrival] = useState<moment.Moment | null>(
    moment(props.train.arrivalTime).add(props.train.delay?.delay, "minutes")
  );
  const [delayReason, setDelayReason] = useState<
    { id: string; name: string } | undefined | null
  >(mapStringToDelay(props.train?.delay?.reason));
  const handleChange = (
    val: moment.Moment | null,
    reason: DelayReason | null | undefined
  ) => {
    setRealArrival(val);
    setDelayReason(reason);
    props.handleChange(val ?? moment(), reason as DelayReason);
  };
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: window.innerWidth < 500 ? "column" : "row",
      }}
    >
      <DateTimePicker
        readOnly={props.readOnly}
        ampm={false}
        onChange={(val) => handleChange(val, delayReason)}
        value={realArrival}
        minDateTime={moment(props.train.arrivalTime)}
        label={<Typography>{t("journeys.delay.realArrival")}</Typography>}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor:
              props.train.delay && props.train.delay?.delay > 5
                ? theme.palette.error.main
                : theme.palette.success.main,
          },
        }}
      />
      <Autocomplete
        readOnly={props.readOnly}
        options={sortBy(delayReasons, (s) => s.id, "desc") ?? []}
        sx={{
          minWidth: "30vw",
          ml: window.innerWidth < 500 ? 0 : 1,
          mt: window.innerWidth < 500 ? 1 : 0,
          color: theme.palette.text.primary,
        }}
        getOptionLabel={(val) => val.name}
        getOptionKey={(val) => val.id}
        value={delayReason}
        onChange={(_e, value) => handleChange(realArrival, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: theme.palette.error.main,
              },
            }}
            label={t("journeys.delay.reason")}
            value={delayReason?.name}
          />
        )}
      />
    </Container>
  );
}
