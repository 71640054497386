import Keycloak from "keycloak-js";
import React, { createContext, useState } from "react";
import {
  AuthContextDefault,
  type AuthContextType,
} from "../interfaces/AuthContext";

export const AuthContext = createContext<AuthContextType>(AuthContextDefault);

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KC_URL ?? "https://iam.teckdigital.de",
  realm: process.env.REACT_APP_KC_REALM ?? "default",
  clientId: process.env.REACT_APP_KC_CLIENT_ID ?? "default",
});

export default function AuthProvider(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const [token, setToken] = useState<string>();

  const getToken = () => {
    return token;
  };

  /**
   * Tries to use existing kc session to login a user or creates a login flow and login the user
   */
  const signIn = async () => {
    if (token || keycloak?.authenticated) return;
    const loginUrl = await keycloak.createLoginUrl({
      redirectUri: window.location.href,
    });
    window.location.replace(loginUrl);

    return;
  };

  const checkAuthentication = async () => {
    try {
      let authenticated = keycloak?.didInitialize
        ? keycloak?.authenticated
        : await keycloak.init({
            adapter: "default",
            onLoad: "check-sso",
            checkLoginIframe: false,
            redirectUri: window.location.href,
          });

      if (authenticated) {
        setToken(keycloak?.token);
      } else {
      }
    } catch (error) {
      console.error("Failed to initialize adapter:", error);
    }
  };

  const signOut = async () => {
    try {
      const logoutUrl = keycloak.createLogoutUrl({
        redirectUri: process.env.REACT_APP_URL,
        logoutMethod: "GET",
      });
      setToken(undefined);
      window.location.replace(logoutUrl);
      return;
    } catch (error) {
      setToken(undefined);
    }
  };

  React.useEffect(() => {
    if (!token) checkAuthentication();
    return () => {
      setToken(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ getToken: getToken, signIn, signOut }}>
      {props.children}
    </AuthContext.Provider>
  );
}
