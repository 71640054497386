import { getTrainColorByCategory } from "@jozys/db-api-wrapper";
import { TrainCategory } from "@jozys/db-api-wrapper/dist/src/types/connection";
import { TrainType } from "@jozys/db-delay-types";
import { Typography, useTheme } from "@mui/material";
import React from "react";
import { parseSize } from "../../create-journey/utils/size";

export interface TrainProps {
  type: TrainType;
  displayName: string;
  portion: number;
  start?: boolean;
  end?: boolean;
}

export default function Train(props: TrainProps) {
  const theme = useTheme();
  return (
    <Typography
      style={{
        flex: props.portion,
        fontWeight: "bold",
        color: theme.palette.getContrastText(
          getTrainColorByCategory(
            props.type as TrainCategory,
            theme.palette.mode === "dark"
          )
        ),
        textWrap: "balance",
        padding: 2,
        fontSize: parseSize(window.innerWidth < 500 ? "small" : "medium", 0.75),
        background: getTrainColorByCategory(
          props.type as TrainCategory,
          theme.palette.mode === "dark"
        ),
        borderRadius: 4,
        marginLeft: props.start ? 0 : 2,
        marginRight: props.end ? 0 : 2,
      }}
    >
      {props.displayName}
    </Typography>
  );
}
