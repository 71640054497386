import { Station } from "@jozys/db-api-wrapper/dist/src/types/station";
import {
  Autocomplete,
  InputProps,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import useAuth from "../../auth/hooks/useAuth";
import { useTranslation } from "react-i18next";

export default function StationSearch(
  props: InputProps & {
    onValueChange: (event: React.SyntheticEvent, value: Station | null) => void;
    label: string;
  }
) {
  const theme = useTheme();
  const auth = useAuth();
  const { t } = useTranslation();
  const [autoComplete, setAutoComplete] =
    React.useState<Omit<Station, "extId">[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const onValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.value.length < 3) return;
    if (
      event.target.value.length === 0 &&
      autoComplete &&
      autoComplete?.length > 0
    ) {
      setAutoComplete([]);
      return;
    }
    setAutoComplete([]);
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/db/station?name=${event.target.value}`,
        {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        }
      )
      .then((res) => {
        setAutoComplete(res.data ?? []);
        setLoading(false);
      });
  };

  return (
    <Autocomplete
      loading={loading}
      onClose={() => setAutoComplete([])}
      autoComplete={autoComplete?.length !== 0 && !loading}
      options={autoComplete ?? []}
      sx={{
        minWidth: "35%",
        background: theme.palette.background.paper,
        p: 1,

        borderRadius: 1,
      }}
      getOptionLabel={(val) => val.name}
      getOptionKey={(val) => val.id}
      onChange={(event, value) => props.onValueChange(event, value)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          error={props.error}
          size="small"
          helperText={
            <Typography
              sx={{
                height: props.error ? 30 : 0,
                color: props.error
                  ? theme.palette.error.main
                  : theme.palette.text.primary,
              }}
            >
              {props.error ? t("inputRequired") : null}
            </Typography>
          }
          label={props.label}
          value={props.value}
          onChange={onValueChange}
        />
      )}
    />
  );
}
