import { Error } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import useAuth from "../../auth/hooks/useAuth";
import CreateJourney from "../../create-journey/components/CreateJourney";
import Home from "../../home/components/Home";
import JourneyDetailed from "../../journey/components/JourneyDetailed";
import JourneysTab from "../../journey/components/JourneysTab";
import Registration from "../../registration/components/Registration";
import Statistics from "../../statistics/components/Statistics";
import Footer from "../design-system/Footer";
import IconText from "../design-system/IconText";
import Menu from "../design-system/Menu";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Settings from "../../settings/components/Settings";

export default function Router() {
  const auth = useAuth();
  const theme = useTheme();

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BrowserRouter>
      <div
        style={{
          background: theme.palette.background.paper,
          minHeight: "100vh",
        }}
      >
        <Menu />
        <div
          style={{
            marginTop: 1,
            minHeight: "80vh",
            background: theme.palette.background.paper,
          }}
        >
          {auth.getToken() != null ? (
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="/journeys" Component={JourneysTab} />
              <Route path="/journeys/create" Component={CreateJourney} />
              <Route path="/journeys/:journeyId" Component={JourneyDetailed} />
              <Route path="/statistics" Component={Statistics} />
              <Route path="/settings" Component={Settings} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" Component={Registration} />
              <Route
                path="*"
                element={
                  <IconText
                    size="large"
                    icon={Error}
                    text="Please login to view this content"
                  />
                }
              />
            </Routes>
          )}
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
