import { Add, ContentPaste } from "@mui/icons-material";
import {
  Fab,
  Pagination,
  PaginationItem,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LoadingWrapper from "../../core/components/LoadingWrapper";
import IconText from "../../core/design-system/IconText";
import { parseSize } from "../../create-journey/utils/size";
import useJourney from "../hooks/useJourney";
import JourneyPreview from "./Journey";

export interface JourneysProps {
  type: "own" | "shared";
}

export default function Journeys(props: JourneysProps) {
  const params = useSearchParams()[0];
  const [loading, setLoading] = React.useState(true);
  const { journeys, fetchJourneys, getPageAmount } = useJourney();
  const [page, setPage] = React.useState(1);
  const [pages, setPageAmount] = React.useState<number>(1);
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  React.useEffect(() => {
    setLoading(true);
    fetchJourneys(
      params.get("page") ? parseInt(params.get("page")!) - 1 : 0
    ).then(() => setLoading(false));
    getPageAmount(5).then((amount) => setPageAmount(amount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    console.log("change");
    console.log("params  " + parseInt(params.get("page") ?? "1"));
    console.log("page " + page);
    const newPage = parseInt(params.get("page") ?? "1");
    if (newPage !== page) {
      if (journeys[newPage - 1] == null || journeys[newPage - 1].length === 0) {
        setLoading(true);
        fetchJourneys(newPage - 1).then(() => setLoading(false));
      }
      setPage(newPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <LoadingWrapper loading={loading}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          alignItems: "center",
          height: "100%",
          paddingBottom: theme.spacing(10),
        }}
      >
        <Typography
          sx={{ fontSize: parseSize("large") }}
          color={"textPrimary"}
          variant="h3"
          textAlign={"left"}
        >
          {props.type === "own"
            ? t("journeys.titleOwn")
            : t("journeys.titleShared")}
        </Typography>
        {journeys && journeys.length > 0 ? (
          journeys[page - 1]?.map((j, i) => (
            <div
              key={j.id}
              style={{ width: window.innerWidth < 500 ? "100%" : "50%" }}
            >
              <JourneyPreview
                showDate
                showIconButton
                onClick={() => navigate(`./${j.id}`, { state: { page: page } })}
                journey={j}
              />
            </div>
          ))
        ) : (
          <IconText
            icon={ContentPaste}
            text={t("journeys.creation.journey.noPersonalJourneys")}
          />
        )}
        <Fab
          color="secondary"
          sx={{ position: "absolute", right: "5%", bottom: "5%" }}
          onClick={() => navigate("create")}
          aria-label="Add new journey"
          key="Add-Journey-FAB"
        >
          <Add />
        </Fab>
        <Pagination
          sx={{ mt: 1 }}
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`${item.page === 1 ? "" : `?page=${item.page}`}`}
              {...item}
            />
          )}
          page={params.get("page") ? parseInt(params.get("page")!) : 1}
          count={pages}
        ></Pagination>
      </div>
    </LoadingWrapper>
  );
}
