import { Container } from "@mui/material";
import React from "react";
import {
  CircleMarker,
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMap,
} from "react-leaflet";

export type StationType = "start" | "end" | "intermediate";

export type StationMarker = {
  lat: number;
  lon: number;
  label: string;
  type: StationType;
};

export interface JourneyMapProps {
  stations: StationMarker[];
  style: React.CSSProperties
}

export default function JourneyMap(props: JourneyMapProps) {
  return (
    <div>
      <MapContainer
        style={props.style}
        center={[50, 8.0]}
        zoom={6}
        fadeAnimation
        boundsOptions={{ animate: true }}
        scrollWheelZoom={false}
      >
        <MapContent {...props} />
      </MapContainer>
    </div>
  );
}

export function MapContent(props: JourneyMapProps) {
  const map = useMap();

  React.useEffect(() => {
    map.flyToBounds(
      props.stations.map((val) => [val.lat, val.lon]),
      { padding: [10, 10], animate: true, easeLinearity: 0.5 }
    );
  }, []);
  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {props.stations.map((station, i) => {
        switch (station.type) {
          case "start":
          case "end":
            return <Marker key={i} position={[station.lat, station.lon]} />;
          case "intermediate":
            return (
              <CircleMarker
                key={i}
                center={[station.lat, station.lon]}
                radius={5}
                pathOptions={{ color: "blue" }}
              />
            );
        }
      })}

      <Polyline
        pathOptions={{ color: "purple" }}
        positions={props.stations.map((val) => [val.lat, val.lon])}
      />
    </>
  );
}
