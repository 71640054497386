import {
  Autocomplete,
  Chip,
  Container,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { stringToColor } from "../../core/design-system/UserAvatar";

export interface GenericAddInputProps<T> {
  getLabel: (o: T | string) => string;
  getKey: (o: T | string) => string;
  getAutoCompleteOptions: (search: string) => Promise<T[]>;
  renderOption: (
    props: React.HTMLAttributes<HTMLLIElement> & { key: any },
    option: T
  ) => React.ReactNode;
  label?: string;
  error?: boolean;
  onSelect: (value: string | T | null) => void;
  selected: T[];
  freeSolo?: boolean;
  removeSelected: (index: number) => void;
}

export default function GenericAddInput<T>(props: GenericAddInputProps<T>) {
  const [options, setOptions] = React.useState<T[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const theme = useTheme();
  const onValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLoading(true);
    props
      .getAutoCompleteOptions(event.target?.value)
      .then((possibleOptions: T[]) => {
        setOptions(possibleOptions);
        setLoading(false);
      });
  };
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        flexDirection: "column",
        alignContent: "center",
        minWidth: 300,
      }}
    >
      <Autocomplete
        options={options}
        loading={loading}
        getOptionKey={(o) => props.getKey(o)}
        getOptionLabel={(o) => props.getLabel(o)}
        renderOption={(optionProps, option) =>
          props.renderOption(optionProps, option)
        }
        onChange={(_e, v) => props.onSelect(v)}
        sx={{ minWidth: 300 }}
        freeSolo={props.freeSolo ?? false}
        renderInput={(params) => (
          <TextField
            {...params}
            error={props.error}
            helperText={
              <Typography sx={{ height: props.error ? 30 : 0 }}>
                {props.error ? "Invalid data provided" : null}
              </Typography>
            }
            label={props.label}
            value={""}
            onChange={onValueChange}
          />
        )}
      ></Autocomplete>
      {props.selected?.length > 0 ? (
        <Container sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          {props.selected.map((s, i) => (
            <Chip
              sx={{
                background: stringToColor(props.getLabel(s)),
                color: theme.palette.getContrastText(
                  stringToColor(props.getLabel(s))
                ),
                ml: 1,
              }}
              onDelete={() => props.removeSelected(i)}
              label={props.getLabel(s)}
            />
          ))}
        </Container>
      ) : (
        <Typography>Nothing selected</Typography>
      )}
    </Container>
  );
}
