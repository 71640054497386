import {
  Connection,
  Train,
} from "@jozys/db-api-wrapper/dist/src/types/connection";
import { Station } from "@jozys/db-api-wrapper/dist/src/types/station";
import { IDelayData, ITrainJourney } from "@jozys/db-delay-types";
import { Error, Search, Start, SwapHoriz, SwapVert } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import LoadingWrapper from "../../core/components/LoadingWrapper";
import Button from "../../core/design-system/Button";
import IconText from "../../core/design-system/IconText";
import JourneyPreview from "../../journey/components/Journey";
import useJourney from "../../journey/hooks/useJourney";
import StationSearch from "../../station/components/StationSearch";
import StepHeader from "../design-system/StepHeader";

export interface FindJourneyProps {
  journeys: Connection[];
  setJourneys: (journeys: Connection[]) => void;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  startStation: Station | undefined;
  endStation: Station | undefined;
  setStartStation: (station: Station | undefined) => void;
  setEndStation: (station: Station | undefined) => void;
}

export default function FindJourney(props: FindJourneyProps) {
  const { searchDBJourney } = useJourney();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [datetime, setDateTime] = React.useState<moment.Moment | null>(
    moment(Date.now())
  );
  const [showNoContent, setShowNoContent] = React.useState(false);
  const { journeys } = props;
  const handleJourneySearch = () => {
    setErrors([]);
    const currentErrors = [];
    if (props.startStation === undefined || props.startStation === null) {
      currentErrors.push("from");
    }
    if (props.endStation === undefined || props.endStation === null) {
      currentErrors.push("to");
    }
    setErrors(currentErrors);
    if (currentErrors.length > 0) {
      return;
    }
    setLoading(true);
    searchDBJourney(
      props.startStation!.id,
      props.endStation!.id,
      datetime!.utc(true).toDate()
    )
      .then((data) => {
        data = data.map((d) => {
          if (
            d.trains.some((t) => t.category === "TRANSFER") ||
            d.trains.some((t) => t.category === "WALK")
          ) {
            d.trains = d.trains.filter((t) => t.category !== "TRANSFER");
            d.trains = d.trains.filter((t) => t.category !== "WALK");
          }
          return d;
        });
        props.setJourneys(data);
        setLoading(false);
        if (data.length === 0) {
          setShowNoContent(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleJourneyChoose = (index: number) => {
    if (props.selectedIndex === index) {
      props.setSelectedIndex(-1);
    } else {
      props.setSelectedIndex(index);
    }
  };
  const theme = useTheme();
  const [errors, setErrors] = React.useState<string[]>([]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Card
        sx={{
          flex: 1,
          background: theme.palette.background.default,
          width: window.innerWidth < 500 ? "90%" : "100%",
        }}
      >
        <StepHeader text={t("journeys.creation.journey.findAndChoose")} />
        <CardContent
          sx={{
            display: "flex",
            alignContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: window.innerWidth < 500 ? "column" : "row",
            }}
          >
            <StationSearch
              required
              error={errors.includes("from")}
              value={props.startStation}
              sx={{ display: "flex", flex: 10 }}
              label={t("journeys.creation.journey.from")}
              onValueChange={(_event, value) => {
                props.setStartStation(value as Station);
              }}
            />
            <IconButton
              disabled
              onClick={() => {
                props.setEndStation(props.startStation);
                props.setStartStation(props.endStation);
              }}
              sx={{
                display: "flex",
                flex: 1,
                alignSelf: "center",
              }}
            >
              {window.innerWidth < 500 ? (
                <SwapVert fontSize="large" />
              ) : (
                <SwapHoriz fontSize="large" />
              )}
            </IconButton>
            <StationSearch
              error={errors.includes("to")}
              required
              value={props.endStation}
              sx={{ display: "flex", flex: 10 }}
              label={t("journeys.creation.journey.to")}
              onValueChange={(_event, value) => {
                props.setEndStation(value as Station);
              }}
            />
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: window.innerWidth < 500 ? "100%" : "30%",
                mt: window.innerWidth < 500 ? 1 : 0,
              }}
            >
              <Button
                sx={{ flex: 1, ml: window.innerWidth < 500 ? 0 : 1 }}
                variant="contained"
                startIcon={<Search />}
                onClick={() => handleJourneySearch()}
              >
                {t("journeys.creation.journey.search")}
              </Button>
            </Box>
          </div>
          <Container
            sx={{
              display: "flex",
              p: 1,
              alignItems: "center",
              mt: 2,
            }}
          >
            <Start />
            <DateTimePicker
              ampm={false}
              format=""
              value={moment(datetime)}
              onChange={(value) => setDateTime(value)}
              label={t("journeys.creation.journey.departure")}
              sx={{
                alignSelf: "flex-start",
                color: theme.palette.text.primary,
                p: 1,

                borderRadius: 1,
              }}
            />
          </Container>
        </CardContent>
      </Card>
      {journeys.length > 0 && (
        <Typography variant="h5" sx={{ mt: 1 }} textAlign="left">
          {t("journeys.creation.journey.possibleJourneys")}
        </Typography>
      )}
      <LoadingWrapper loading={loading}>
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          {journeys.length > 0 ? (
            journeys.map((j: Connection, i) => (
              <div style={{ width: "100%" }} key={i}>
                <JourneyPreview
                  showIconButton
                  key={i}
                  disabled={
                    props.selectedIndex !== -1 && props.selectedIndex !== i
                  }
                  onClick={() => handleJourneyChoose(i)}
                  additionalInformation={[
                    moment
                      .utc(
                        moment.duration(j.duration, "seconds").asMilliseconds()
                      )
                      .format("HH:mm"),
                  ]}
                  journey={{
                    ...j,
                    id: j.id ?? i.toString(),
                    startStation: j.from,
                    endStation: j.to,
                    delay: j.delay ?? 0,
                    trains: j.trains.map(
                      (t: Train) =>
                        ({
                          ...t,
                          line: parseInt(t.number),
                          displayName: t.displayName,
                          type: t.category.toUpperCase(),
                          delayData: t.delay as unknown as IDelayData,
                          portion: Math.round((t.duration / j.duration) * 100),
                          arrival: new Date(t.arrivalTime),
                          departure: new Date(t.departureTime),
                        } as ITrainJourney)
                    ),
                  }}
                />
              </div>
            ))
          ) : (
            <IconText
              icon={showNoContent ? Error : Search}
              text={
                showNoContent
                  ? t("journeys.creation.journey.noJourneys")
                  : t("journeys.creation.journey.searchRecommendation")
              }
            />
          )}
        </div>
      </LoadingWrapper>
    </div>
  );
}
