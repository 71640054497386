import { IJourneyPreview } from "@jozys/db-delay-types";
import { Palette } from "@mui/material";
import { sortBy } from "lodash";

export function calculatePunctuality(journeys: IJourneyPreview[]) {
  if (journeys.length) {
    const punctualJourneys = journeys.filter((j) => j.delay && j?.delay <= 5);
    return Math.round((punctualJourneys.length / journeys.length) * 100) ?? 0;
  }
  return 100;
}

export function calculateSumOfDelay(journeys: IJourneyPreview[]) {
  let sumOfDelays = 0;
  journeys.forEach((j) => (sumOfDelays += j?.delay));

  return `${Math.floor(sumOfDelays / 60)}h ${Math.round(
    ((sumOfDelays / 60) % 1) * 60
  )}min`;
}

export function calculateAverageDelay(journeys: IJourneyPreview[]) {
  let sumOfDelays = 0;
  journeys.forEach((j) => (sumOfDelays += j?.delay));
  return `${Math.floor(sumOfDelays / journeys.length)}min ${Math.round(
    ((sumOfDelays / journeys.length) % 1) * 60
  )}sec`;
}

export function getHighestDelay(journeys: IJourneyPreview[]) {
  if (!journeys.length) return 0;
  journeys = sortBy(journeys, (j) => j.delay).reverse();
  return journeys[0].delay;
}

export function getPunctialityColor(punctuality: number, palette: Palette) {
  switch (true) {
    case punctuality >= 80:
      return palette.success.main;
    case punctuality < 80 && punctuality >= 60:
      return palette.warning.main;
    case punctuality < 60 && punctuality >= 40:
      return palette.warning.dark;
    case punctuality < 40 && punctuality >= 20:
      return palette.error.main;
    case punctuality < 20:
      return palette.error.dark;
    default:
      return "#980000";
  }
}
